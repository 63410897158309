import React from "react";
import PropTypes from "prop-types";
import SvgIconGift from "../../../../../assets/images/vector/components/webinars/icon-gift.svg";
import SvgIconLogo from "../../../../../assets/images/vector/components/common/dark.svg";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { getTrueClasses } from "../../../../../helpers";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span>{text}</span>,
  },
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children, i) => (
      <li key={i}>
        <img src={SvgIconGift} alt="." />
        <p>{children}</p>
      </li>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => children ?? null,
  },
};

const SeeDetailsBonus = ({ list, isSecond, isLongInterval }) => {
  return (
    <div
      className={getTrueClasses(
        "SeeDetailsBonus",
        !!isSecond && "SeeDetailsBonus__second",
        !!isLongInterval &&
          (isSecond
            ? "SeeDetailsBonus__second__longInterval"
            : "SeeDetailsBonus__longInterval")
      )}
    >
      <div className="SeeDetailsBonus__title">
        {isSecond ? (
          <>
            <img src={SvgIconLogo} alt="AMZ" />
            <p>
              Full annual plan toolset from <span>AMZScout</span> is included as
              well!
            </p>
          </>
        ) : (
          "Bonuses"
        )}
      </div>
      {isSecond ? (
        <ul>
          {list.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      ) : (
        renderRichText(list, options)
      )}
    </div>
  );
};

SeeDetailsBonus.prototype = {
  list: PropTypes.object.isRequired,
  isLongInterval: PropTypes.bool.isRequired,
  isSecond: PropTypes.bool,
};

export default SeeDetailsBonus;
