import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import SeeDetailsBonus from "./SeeDetailsItemBonus";
import { getTrueClasses } from "../../../../../helpers";
import "./index.scss";

const options = {
  renderText: (text) =>
    text
      .split("\n")
      .flatMap((text, i) => [
        i > 0 && <br key={i} />,
        <Fragment key={i + "a"}>{text}</Fragment>,
      ]),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <div className="SeeDetailsOffer__description">{children}</div>
    ),
  },
};

const SeeDetailsOffer = (props) => {
  const {
    offerDescription,
    cta: { ctaTitle, offerLink, klarnaLink },
    tools,
    bonuses,
    offerRef,
    isWaMode,
    spacingBetweenBonuses,
    spacingBetweenTools,
  } = props;

  return (
    <div
      className={getTrueClasses(
        "SeeDetailsOffer",
        !!isWaMode && "SeeDetailsOffer__WaMode"
      )}
      ref={offerRef}
    >
      <div className="SeeDetailsOffer__title">Special Offer</div>
      <div className="SeeDetailsOffer-plan">
        <div className="SeeDetailsOffer__wrapDescription">
          {renderRichText(offerDescription, options)}
        </div>
        <div className="SeeDetailsOffer-plan__plus" />
        <SeeDetailsBonus
          list={bonuses}
          isLongInterval={spacingBetweenBonuses}
        />
        <SeeDetailsBonus
          isSecond
          list={tools}
          isLongInterval={spacingBetweenTools}
        />
      </div>
      <div className="SeeDetailsOffer-cta">
        <p className="SeeDetailsOffer-cta__title">{ctaTitle}</p>
        <div className="SeeDetailsOffer-cta-buttons">
          <a
            href={offerLink.path}
            target="_blank"
            className="SeeDetailsOffer-cta__greenBtn"
          >
            {offerLink.text}
          </a>
          {klarnaLink.path && klarnaLink.text && (
            <a
              href={klarnaLink.path}
              target="_blank"
              className="SeeDetailsOffer-cta__klarnaBtn"
            >
              {klarnaLink.text}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

SeeDetailsOffer.prototype = {
  offerDescription: PropTypes.object.isRequired,
  cta: PropTypes.object.isRequired,
  bonuses: PropTypes.object.isRequired,
  tools: PropTypes.object.isRequired,
  offerRef: PropTypes.object.isRequired,
  isWaMode: PropTypes.bool.isRequired,
  spacingBetweenBonuses: PropTypes.bool.isRequired,
  spacingBetweenTools: PropTypes.bool.isRequired,
};

export default SeeDetailsOffer;
