import React from "react";
import PropTypes from "prop-types";
import UniversityName from "../../../common/webinars/UniversityName";
import UniversityInfo from "../../../common/webinars/UniversityInfo";
import "./index.scss";
import SeeDetailsOffer from "./SeeDetailsItemOffer";

const SeeDetailsItem = ({
  info,
  updatePlayerState,
  h1,
  offerRef,
  isWaMode,
  heightPage,
}) => {
  const cta = {
    ctaTitle: (
      <>
        ALL THIS FOR ONLY <span>${info.offerPrice}</span>{" "}
        <span>${info.offerDiscount}</span>
      </>
    ),
    offerLink: {
      text: `Get the Tools, Training and Bonuses for only $${info.offerDiscount}`,
      path: info.offerUrl,
    },
    klarnaLink: {
      text: info.klarnaButtonTitle,
      path: info.klarnaButtonUrl,
    },
  };
  return (
    <div className="SeeDetailsItem">
      <UniversityName
        title={info.courseTitle}
        description={info.courseDescription}
        pathLink=""
        h1={h1}
        isWaMode={isWaMode}
      />
      <UniversityInfo
        title={info.person.nameSurname}
        img={info.person.photo}
        priceOne={info.offerPrice}
        priceTwo={info.offerDiscount}
        description={info.person.description}
        textLink="Get Special Offer"
        pathLink=""
        urlYoutube={info.person.videoUrl}
        updatePlayerState={updatePlayerState}
        offerRef={offerRef}
        isWaMode={isWaMode}
        heightPage={heightPage}
      />
      <SeeDetailsOffer
        offerDescription={info.offerDescription}
        cta={cta}
        bonusesTitle="Bonuses"
        bonuses={info.offerBonuses}
        tools={info.offerTools}
        offerRef={offerRef}
        isWaMode={isWaMode}
        spacingBetweenBonuses={info.offerBonusesSpacingBetweenListItems}
        spacingBetweenTools={info.offerToolsSpacingBetweenListItems}
      />
    </div>
  );
};

SeeDetailsItem.propTypes = {
  info: PropTypes.object.isRequired,
  updatePlayerState: PropTypes.func.isRequired,
  h1: PropTypes.string.isRequired,
  isWaMode: PropTypes.bool.isRequired,
  offerRef: PropTypes.object.isRequired,
  heightPage: PropTypes.number,
};

export default SeeDetailsItem;
